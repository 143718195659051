<template>
  <div>

    <b-row>

      <b-col cols="12">
        <b-card-actions
          v-if="operacao.p && operacao.p.dv"
          title="Dasboard"
          action-collapse
        >
          <b-row>

            <b-col class="mb-1">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ operacao.p.dv.ativo.toUpperCase() }}
                </h2>
                <span>Ativo</span>
              </div>
            </b-col>

            <b-col class="mb-1">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  <template v-if="operacao.p.dv.cotacao">
                    <div class="text-nowrap">
                      <span
                        :class="parseFloat(operacao.p.dv.cotacao) >= parseFloat(operacao.p.dv.precoEntrada)? 'text-success' : 'text-danger'"
                      >
                        {{ percentDiferenca(operacao.p.dv.precoEntrada, operacao.p.dv.cotacao) }}
                      </span>
                    </div>
                  </template>
                </h2>
                <span>Lucro (%)</span>
              </div>
            </b-col>

            <b-col class="mb-1">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ operacao.p.dv.direcao }}
                </h2>
                <span>Direção</span>
              </div>
            </b-col>

            <b-col class="mb-1">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formatDateTimeUTC0(operacao.p.dv.dataEntrada) }}
                </h2>
                <span>Data Entrada <small>(UTC-0)</small></span>
              </div>
            </b-col>
          </b-row>

        </b-card-actions>
      </b-col>

      <b-col cols="12">
        <b-card-actions
          v-if="operacao.p && operacao.p.dv"
          title="Controle"
          action-collapse
        >
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                @click.stop="confirmarEncerrarAgora(operacao.id)"
              >
                <feather-icon
                  icon="TargetIcon"
                  class="mr-50"
                />
                <span class="align-middle">Encerrar operação</span>
              </b-button>
            </b-col>

            <b-col>
              <b-form-checkbox
                v-model="operacao.saidaAutomaticaDesativado"
                class="custom-control-primary"
                @change="confirmarSaidaAutomaticaDesativado(operacao.id, operacao.saidaAutomaticaDesativado)"
              >
                Saída automática está <strong>{{ operacao.saidaAutomaticaDesativado ? 'DESATIVADA' : 'ATIVADA' }}</strong>
              </b-form-checkbox>
            </b-col>

          </b-row>

        </b-card-actions>
      </b-col>

    </b-row>

    <b-row v-if="operacao.p">
      <b-col
        v-for="grafico in operacao.p.g"
        :key="grafico.id"
        cols="12"
      >
        <b-card-actions
          :title="grafico.nome"
          action-collapse
        >
          <div class="chart-container">
            <LWChart
              :grafico="grafico"
              :series="grafico.series"
              :series-update="grafico.series"
            />
          </div>
        </b-card-actions>
      </b-col>
    </b-row>

  </div>
</template>

<script>
/* eslint-disable */

import {
  BCard, BTable, BFormCheckbox, BDropdown, BButton, BDropdownItem, BCardBody, BCardHeader, BMedia, BMediaAside, BAvatar, BMediaBody, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import store from '@/store'
import { formatDateTimeBR, formatDateTimeUTC0, percentDiferenca } from '@/utils/filter'
import utilsMixin from '@/mixins/utilsMixin'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import LWChart from '@/layouts/components/custom/LWChart2.vue'
import SockJS from "sockjs-client"
import Stomp from "stompjs"
import operadorStoreModule from './operadorStoreModule'

const MONITOR_OPERADOR_ESTRATEGIA_INSTANCIA_VIEW_STORE_MODULE_NAME = 'monitor-operador-estrategia-instancia-view'

let stompClient = null

export default {

  components: {
    BCard,
    BTable,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BRow,
    BCol,
    BCardText,
    LWChart,
    BCardActions,
    BButton
  },

  directives: {
    Ripple,
  },
  
  mixins: [utilsMixin],

  data() {
    return {
      formatDateTimeBR,
      formatDateTimeUTC0,
      percentDiferenca,
      saidaAutomaticaDesativado: false,
      operacao: [],
      operadorData: null,
    }
  },

  computed: {
    
  },

  watch: {
  },

  created() {
    if (!store.hasModule(MONITOR_OPERADOR_ESTRATEGIA_INSTANCIA_VIEW_STORE_MODULE_NAME)) store.registerModule(MONITOR_OPERADOR_ESTRATEGIA_INSTANCIA_VIEW_STORE_MODULE_NAME, operadorStoreModule)
    this.fetchList(this.$route.params.idOp)
  },

  beforeDestroy() {
    if (store.hasModule(MONITOR_OPERADOR_ESTRATEGIA_INSTANCIA_VIEW_STORE_MODULE_NAME)) store.unregisterModule(MONITOR_OPERADOR_ESTRATEGIA_INSTANCIA_VIEW_STORE_MODULE_NAME)
  },

  methods: {

    async getOperadorContexto() {
      const response = await this.$store.dispatch('monitor-operador-estrategia-instancia-view/getOperadorContexto')
      return response.data
    },

    async fetchList(id) {
      this.operadorData = await this.getOperadorContexto() // Await the Promise
      this.getOperacao(this.operadorData, id)
    },

    iniciaWs(idInstancia, contexto) {
      let socket = new SockJS(`${process.env.VUE_APP_BASE_URL}${contexto}/instancia`);
      const self = this
		  stompClient = Stomp.over(socket);
		   stompClient.connect({}, function (frame) {		 
          let isFirst = true
          stompClient.subscribe(`/info/${idInstancia}`, function(val) {	
            const jsonWs = JSON.parse(val.body)

            if (self.operacao.p.dvp) {
              self.operacao.p.dvp.pnlLiquido = self.toFixed(jsonWs.pnlLiquido, 4)
              self.operacao.p.dvp.custo = self.toFixed(jsonWs.custo, 6)

            } else if (self.operacao.p.dv) {
              self.operacao.p.dv.pnlLiquido = self.toFixed(jsonWs.pnlLiquido, 4)
              self.operacao.p.dv.custo = self.toFixed(jsonWs.custo, 6)
            }

            if (isFirst) {
              // removendo milisegundos do time
              jsonWs.p.g.forEach(g => {
                  g.series.forEach(s => {
                    if (s.tipoSerie === 'CANDLESTICK' || s.tipoSerie === 'BAR') {
                      s.candlestickSerie.forEach(candle => {
                        candle.time =  candle.time / 1000
                      })
                    }else{
                      s.serieTemporal.forEach(serie => {
                        serie.time =  serie.time / 1000
                      })
                    }
                  })
                })
              self.operacao =  jsonWs
              isFirst = false
            }else{
              // atualização

              jsonWs.p.g.forEach(newDataG => {
                const oldDataGIndex = self.operacao.p.g.findIndex(g => g.id === newDataG.id);
                if (oldDataGIndex >= 0) {
                  const oldDataG = self.operacao.p.g[oldDataGIndex];
                  newDataG.series.forEach(newDataS => {
                    if (newDataS.tipoSerie === "CANDLESTICK" || newDataS.tipoSerie === "BAR") {
                      newDataS.candlestickSerie.forEach(candle => {
                        candle.time = candle.time / 1000
                      })
                    } else {
                      newDataS.serieTemporal.forEach(serie => {
                        serie.time = serie.time / 1000
                      })
                    }

                    const oldDataSIndex = oldDataG.series.findIndex(s => s.nome === newDataS.nome);
                    if (oldDataSIndex >= 0) {
                      oldDataG.series.splice(oldDataSIndex, 1, newDataS);
                    } else {
                      oldDataG.series.push(newDataS);
                    }
                  });
                  self.operacao.p.g.splice(oldDataGIndex, 1, oldDataG);
                } else {
                  self.operacao.p.g.push(newDataG);
                }
              });

              self.operacao.p.dv =  jsonWs.p.dv
            }
          });
      });
    },

    async getOperacao(operadorData, id) {
      this.$store.dispatch('monitor-operador-estrategia-instancia-view/getOperacao', { id, operadorData })
        .then(response => { 

          this.operacao = response.data

          this.operacao.p.g.forEach(g => {
            g.series.forEach(s => {
              if (s.tipoSerie === 'CANDLESTICK' || s.tipoSerie === 'BAR') {
                s.candlestickSerie.forEach(candle => {
                  candle.time =  candle.time / 1000
                })
              }else{
                s.serieTemporal.forEach(serie => {
                  serie.time =  serie.time / 1000
                })
              }
            })

          })
          
          this.iniciaWs(this.operacao.idInstancia, operadorData.contexto)

        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Registro não encontrado',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    // Encerrar Agora
    confirmarSaidaAutomaticaDesativado(id, saidaAutomaticaDesativado) {
      this.$swal({
        title: `Tem certeza que deseja ${saidaAutomaticaDesativado? 'DESABILITAR': 'HABILITAR'} a saída automática?`,
        text: `Sua saída automática da operação será ${saidaAutomaticaDesativado? 'desabilitada': 'habilitada'}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode alterar!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.alterarSaidaAutomatica(id, this.operadorData, saidaAutomaticaDesativado)
        }
      })
    },

    alterarSaidaAutomatica(id, operadorData, modo) {
      this.$store.dispatch('monitor-operador-estrategia-instancia-view/alterarSaidaAutomatica', { id, operadorData, modo })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Alterando saída!',
            text: 'Sua saída está sendo alterada',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },

    // Encerrar Agora
    confirmarEncerrarAgora(id) {
      this.$swal({
        title: 'Tem certeza que deseja encerrar a posição agora?',
        text: 'Sua posição será encerrada pelo preço de mercado',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode encerrar!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.encerrarAgora(id, this.operadorData)
        }
      })
    },

    encerrarAgora(id, operadorData) {
      this.$store.dispatch('monitor-operador-estrategia-instancia-view/encerrarOperacao', { id, operadorData })
        .then(() => {
          this.getOperacao(operadorData, id)
          this.$swal({
            icon: 'success',
            title: 'Encerrando operação!',
            text: 'Sua operação está sendo encerrada',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },

    formataValor(value, limit, convertUSD) {
      if (value) {
        if (convertUSD) {
          return value.toLocaleString('pt-BR', { style: 'currency', currency: 'USD' })
        }
        if (limit) {
          return parseFloat(value).toFixed(limit)
        }
        return value
      }
      return '-'
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
