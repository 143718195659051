<template>
  <div
    :ref="grafico.id"
    class="lw-chart"
  />
</template>

<script>

import { createChart, LineStyle, CrosshairMode } from 'lightweight-charts'

const chartOptions = {
  height: 600,
  timeScale: {
    timeVisible: true,
  },
  layout: {
    background: { color: 'rgb(0, 0, 0)' },
    textColor: '#DDD',
  },
  grid: {
    vertLines: { color: 'rgb(27, 31, 35)', style: LineStyle.Dotted },
    horzLines: { color: 'rgb(27, 31, 35)', style: LineStyle.Dotted },
  },
  crosshair: {
    // Change mode from default 'magnet' to 'normal'.
    // Allows the crosshair to move freely without snapping to datapoints
    mode: CrosshairMode.Normal,

    // Vertical crosshair line (showing Date in Label)
    vertLine: {
      width: 2,
      color: '#C3BCDB44',
      style: LineStyle.Dotted,
      labelBackgroundColor: '#9B7DFF',
    },

    // Horizontal crosshair line (showing Price in Label)
    horzLine: {
      width: 2,
      color: '#C3BCDB44',
      style: LineStyle.Dotted,
      labelBackgroundColor: '#9B7DFF',
    },
  },
  watermark: {
    visible: true,
    fontSize: 100,
    horzAlign: 'center',
    vertAlign: 'center',
    color: 'rgba(171, 71, 188, 0.18)',
    text: '',
  },
}

const priceScaleOptions = {
  borderColor: '#71649C',
}

const timeScaleOptions = {
  borderColor: '#71649C',
  rightOffset: 40,
}

function addChartSeries(chart, serie) {
  let chartSeries

  switch (serie.tipoSerie) {
    case 'LINE':
      chartSeries = chart.addLineSeries()
      chartSeries.setData(serie.serieTemporal)
      break
    case 'HISTOGRAM':
      chartSeries = chart.addHistogramSeries()
      chartSeries.setData(serie.serieTemporal)
      break
    case 'AREA':
      chartSeries = chart.addAreaSeries()
      chartSeries.setData(serie.serieTemporal)
      break
    case 'CANDLESTICK':
      chartSeries = chart.addCandlestickSeries()
      chartSeries.setData(serie.candlestickSerie)
      break
    case 'BAR':
      chartSeries = chart.addBarSeries()
      chartSeries.setData(serie.candlestickSerie)
      break
    case 'BASELINE':
      chartSeries = chart.addBaselineSeries()
      chartSeries.setData(serie.serieTemporal)
      break
    default:
      throw new Error(`Invalid serie type: ${serie.tipoSerie}`)
  }

  if (serie.seriesOptions) {
    chartSeries.applyOptions(serie.seriesOptions)
  }

  return chartSeries
}

// Auto resizes the chart when the browser window is resized.
function resizeHandler(container, chart) {
  if (!chart || !container) return
  const dimensions = container.getBoundingClientRect()
  chart.resize(dimensions.width, dimensions.height)
}

export default {
  props: {
    grafico: {
      type: Object,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    seriesUpdate: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      seriesInstance: {},
      chart: null,
    }
  },

  watch: {

    seriesUpdate(newData) {
      if (!this.seriesInstance) return

      newData.forEach(s => {
        if (this.seriesInstance[s.nome]) {
          if (s.tipoSerie === 'CANDLESTICK' || s.tipoSerie === 'BAR') {
            this.seriesInstance[s.nome].update(s.candlestickSerie[0])
          } else {
            this.seriesInstance[s.nome].update(s.serieTemporal[0])
          }
        } else {
          this.seriesInstance[s.nome] = addChartSeries(this.chart, s)
        }

        // atualiza os markers
        if (s.markers) {
          s.markers.forEach(m => {
            // eslint-disable-next-line no-param-reassign
            m.time /= 1000
            if (!this.seriesInstance[s.nome].markerItems) {
              this.seriesInstance[s.nome].markerItems = []
            }

            this.seriesInstance[s.nome].markerItems.push(m)
            this.seriesInstance[s.nome].setMarkers(this.seriesInstance[s.nome].markerItems)
          })
        }

        // atualiza os pricelines
        if (s.priceLines) {
          // atualiza os pricelines enviados a instancias de pricelines
          s.priceLines.forEach(p => {
            // eslint-disable-next-line no-param-reassign
            p.price = parseFloat(parseFloat(p.price).toFixed(8))
            const priceLine = this.seriesInstance[s.nome].priceLinesInstance && this.seriesInstance[s.nome].priceLinesInstance.find(pl => pl.wh.un.id === p.id)

            if (priceLine) { // atualiza um ja existente
              priceLine.applyOptions(p)
            } else {
              // adiciona um novo
              const priceLineInstance = this.seriesInstance[s.nome].createPriceLine(p)
              if (!this.seriesInstance[s.nome].priceLinesInstance) {
                this.seriesInstance[s.nome].priceLinesInstance = [priceLineInstance]
              } else {
                this.seriesInstance[s.nome].priceLinesInstance.push(priceLineInstance)
              }
            }
          })

          // Remove os objetos priceLine que não existem mais no novo array s.priceLines
          if (this.seriesInstance[s.nome].priceLinesInstance) {
            this.seriesInstance[s.nome].priceLinesInstance.forEach((priceLine, index) => {
              const exists = s.priceLines.find(p => p.id === priceLine.wh.un.id)
              if (!exists) {
                this.seriesInstance[s.nome].removePriceLine(priceLine)
                this.seriesInstance[s.nome].priceLinesInstance.splice(index, 1)
              }
            })
          }
        } else if (this.seriesInstance[s.nome].priceLinesInstance) {
          // delete todos os pricelines da series quando não existe priceline no ws
          if (!s.priceLines) {
            this.seriesInstance[s.nome].priceLinesInstance.forEach(p => {
              this.seriesInstance[s.nome].removePriceLine(p)
            })
            this.seriesInstance[s.nome].priceLinesInstance = null
          } else {
            this.seriesInstance[s.nome].priceLinesInstance.forEach(p => {
              const priceLine = s.priceLines.find(pl => pl.id === p.id.wh.un.id)
              const index = this.seriesInstance[s.nome].priceLinesInstance.indexOf(p)
              this.seriesInstance[s.nome].removePriceLine(priceLine)
              this.seriesInstance[s.nome].priceLinesInstance.splice(index, 1)
            })
          }
        }
      })
    },
  },
  mounted() {
    // Create the Lightweight Charts Instance using the container ref.

    chartOptions.watermark.text = this.grafico.nome

    this.chart = createChart(this.$refs[this.grafico.id], chartOptions)

    this.chart.priceScale().applyOptions(priceScaleOptions)
    this.chart.timeScale().applyOptions(timeScaleOptions)

    this.series.forEach(s => {
      const chartSeries = addChartSeries(this.chart, s)

      this.seriesInstance[s.nome] = chartSeries

      if (s.markers) {
        this.seriesInstance[s.nome].markerItems = []
        s.markers.forEach(m => {
          this.seriesInstance[s.nome].markerItems.push(m)
        })
        this.seriesInstance[s.nome].setMarkers(this.seriesInstance[s.nome].markerItems)
      }

      if (s.priceLines) {
        s.priceLines.forEach(p => {
          // eslint-disable-next-line no-param-reassign
          p.price = parseFloat(parseFloat(p.price).toFixed(8))
          const priceLineInstance = chartSeries.createPriceLine(p)
          if (!this.seriesInstance[s.nome].priceLinesInstance) {
            this.seriesInstance[s.nome].priceLinesInstance = [priceLineInstance]
          } else {
            this.seriesInstance[s.nome].priceLinesInstance.push(priceLineInstance)
          }
        })
      }
    })

    this.chart.timeScale().fitContent()

    window.addEventListener('resize', () => resizeHandler(this.$refs[this.grafico.id], this.chart))
  },

  unmounted() {
    this.clearAll()
  },

  methods: {
    clearAll() {
      if (this.chart) {
        this.chart.remove()
        this.chart = null
      }
      window.removeEventListener('resize', resizeHandler)
    },

    getSeriesInstances() {
      return this.seriesInstance
    },
  },

}
</script>

<style scoped>

.lw-chart {
  width: 100%;
  height: 100%;
}
</style>
