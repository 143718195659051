import axios from '@axios'
import { subscribeSymbol } from '@/services/binance/spot/binance'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getOperadorContexto() {
      return new Promise((resolve, reject) => {
        axios.get('/agente/operador-contexto')
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getOperadorEstrategiaInstancias(context, params) {
      return new Promise((resolve, reject) => {
        axios.get(`/${params.contexto}/monitor/operador-estrategia-instancia`, {
          headers: {
            token: params.token,
          },
        })
          .then(response => {
            response.data.forEach(operador => {
              operador.instancias.forEach(i => {
                if (i.ativos && i.posicionado) {
                  subscribeSymbol(i.ativos)
                }
              })
            })
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    getOperacao(context, params) {
      return new Promise((resolve, reject) => {
        axios.get(`/${params.operadorData.contexto}/monitor/operacao/${params.id}`, {
          headers: {
            token: params.operadorData.token,
          },
        })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    encerrarOperacao(context, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/${params.operadorData.contexto}/agente/finalizar-operacao/${params.id}`, {},
          {
            headers: {
              token: params.operadorData.token,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    alterarSaidaAutomatica(context, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/${params.operadorData.contexto}/agente/saida-automatica-operacao/${params.id}`, params.modo.toString(), {
          headers: {
            'Content-Type': 'application/json',
            token: params.operadorData.token,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
